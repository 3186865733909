import {
  CheckoutDefault,
  CreateSellerToken,
  SellerInvoice,
  SellerNotifications,
  SellerNotificationsActive,
  SellerNotificationsBasic,
  EditSellerTokenActive,
  SellerWebhooks,
  DefaultTax,
} from "@/models/Settings";
import { Api } from "@/services";
import { useState } from "react";

export function useSettings() {
  const baseUrl = "/seller-notifications";
  const baseUrlCheckoutDefault = "/sellers";
  const baseUrlTokens = "/seller-api-tokens";
  const baseUrlWebhooks = "/seller-api-webhooks";
  const baseUrlBankingAccount = "/service/banking/accounts";
  const [info, setInfo] = useState(true);
  const [bank, setBank] = useState(false);
  const [PIX, setPIX] = useState(false);
  const [credit, setCredit] = useState(false);
  const patchBasic = (data: SellerNotificationsBasic) => {
    return Api.patch<SellerNotificationsBasic>(`${baseUrl}/basic`, data);
  };
  const patchActive = (data: SellerNotificationsActive) => {
    return Api.patch<SellerNotificationsActive>(`${baseUrl}/active`, data);
  };
  const getSellerApiWebhooks = () => {
    return Api.get(`${baseUrlWebhooks}`);
  };
  const postSellerApiWebhook = (
    data: Pick<SellerWebhooks, "title" | "domains" | "url">
  ) => {
    return Api.post(baseUrlWebhooks, data);
  };
  const putSellerApiWebhook = (
    uuid: string,
    data: Pick<SellerWebhooks, "title" | "domains" | "url" | "active">
  ) => {
    return Api.put(`${baseUrlWebhooks}/${uuid}`, data);
  };
  const patchActiveSellerApiWebhook = (
    uuid: string,
    data: Pick<SellerWebhooks, "active">
  ) => {
    return Api.patch(`${baseUrlWebhooks}/${uuid}/active`, data);
  };
  const deleteActiveSellerApiWebhook = (uuid: string) => {
    return Api.delete(`${baseUrlWebhooks}/${uuid}`);
  };
  const getSellerApiTokens = () => {
    return Api.get(`${baseUrlTokens}`);
  };
  const postSellerApiToken = (data: CreateSellerToken) => {
    return Api.post(`${baseUrlTokens}`, data);
  };
  const patchActiveSellerApiToken = (
    uuid: string,
    data: EditSellerTokenActive
  ) => {
    return Api.patch(`${baseUrlTokens}/${uuid}/active`, data);
  };
  const putSellerApiToken = (uuid: string, data: EditSellerTokenActive) => {
    return Api.put(`${baseUrlTokens}/${uuid}`, data);
  };
  const deleteSellerApiToken = (uuid: string) => {
    return Api.delete(`${baseUrlTokens}/${uuid}`);
  };
  const getSellerNotifications = () => {
    return Api.get<SellerNotifications>(`${baseUrl}`);
  };
  const getSellerInvoiceStatus = () => {
    return Api.get<SellerInvoice>(`${baseUrlCheckoutDefault}/invoice-config`);
  };
  const patchMigrationActive = (data: Object) => {
    return Api.patch(`${baseUrl}/migration/active`, data);
  };
  const getSellerCheckoutDefault = () => {
    return Api.get<CheckoutDefault>(
      `${baseUrlCheckoutDefault}/default-payments-method`
    );
  };
  const patchSellerCheckoutDefault = (data: any) => {
    return Api.patch(`${baseUrlCheckoutDefault}/payment-methods`, data);
  };
  const patchDefaultTax = (data: DefaultTax) => {
    return Api.patch(`${baseUrlCheckoutDefault}/default-tax`, data);
  }
  const getDefaultTax = () => {
    return Api.get<DefaultTax>(`${baseUrlCheckoutDefault}/default-tax`);
  }
  const desactivateSellerInvoice = () => {
    return Api.patch(`${baseUrlCheckoutDefault}/invoice-config`, {
      active: false,
    });
  };
  const getBankingAccount = () => {
    return Api.get(baseUrlBankingAccount);
  }

  const postBankingAccount = (data: any) => {
    return Api.post(baseUrlBankingAccount, data)
  }

  function handleBarChange(name: string) {
    if (name === "generalInfo") {
      setInfo(true);
      setBank(false);
      setPIX(false);
      setCredit(false);
    }

    if (name === "bankSlip") {
      setInfo(false);
      setBank(true);
      setPIX(false);
      setCredit(false);
    }

    if (name === "PIX") {
      setInfo(false);
      setBank(false);
      setPIX(true);
      setCredit(false);
    }

    if (name === "creditCard") {
      setInfo(false);
      setBank(false);
      setPIX(false);
      setCredit(true);
    }
  }

  return {
    patchBasic,
    patchActive,
    getSellerNotifications,
    getSellerInvoiceStatus,
    patchMigrationActive,
    getSellerCheckoutDefault,
    patchSellerCheckoutDefault,
    desactivateSellerInvoice,
    getSellerApiTokens,
    postSellerApiToken,
    patchActiveSellerApiToken,
    putSellerApiToken,
    deleteSellerApiToken,
    getSellerApiWebhooks,
    putSellerApiWebhook,
    patchActiveSellerApiWebhook,
    postSellerApiWebhook,
    deleteActiveSellerApiWebhook,
    handleBarChange,
    patchDefaultTax,
    getDefaultTax,
    getBankingAccount,
    postBankingAccount,
    info,
    bank,
    PIX,
    credit,
  };
}
